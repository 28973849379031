import React,{ useRef } from 'react';
import StaffForm from '../components/StaffForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin,Popover,Popconfirm } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined,DeleteOutlined,EditOutlined } from '@ant-design/icons';
import { SearchOutlined,CameraOutlined,PlusOutlined,LoadingOutlined,MailFilled } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import * as serverconfig from '../serverconn'
import CurrencyFormat from 'react-currency-format';
import * as reactstrp from 'reactstrap';

import {
  Col,
  Row,
} from 'reactstrap';
var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;


var sacco= ''
var bizuserid= ''
var token= ''

class ReportToPrint extends React.Component {

    state = {
      companyprofile:{},
    };
  
    componentDidMount(){

      if(localStorage.getItem("sacco")){
         sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
         bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
      
      }else{
         sacco= ''
         bizuserid= ''
      }
      
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }


      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

      axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
      .then(res => {  
          this.setState({
            companyprofile: res.data
          })
  
      })

  
    }
  
    render() {
      return (
        <div style={{padding:20}}>
 <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>
                  <h5>Country: {this.state.companyprofile.country} || Currency: {this.state.companyprofile.currency}</h5>
                
                  </Col>

       </Row>

             <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>FINANCIAL STATEMENT REPORT FOR {this.props.selectedmemberobject.AccountNumber} | {this.props.selectedmemberobject.FullName} FROM: {this.props.dateone}  TO: {this.props.datetwo}</h3>
             <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Desc</th>
                          <th>Sub account</th>

                          <th>Debit</th>
                          <th>Credit</th>
                          
                          <th>Savings balance</th>
                          <th>Shares #</th>
                          <th>Shares Amount</th>
                          <th>Loan balance</th>

                          <th>Savings loan security</th>
                          <th>Shares loan security</th>

                          {
                            this.state.companyprofile.showwelfare?
                            <th>Welfare</th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showemergency?
                            <th>Emergency</th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showdream?
                            <th>Dream</th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showlegacy?
                            <th>Legacy</th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showchildren?
                            <th>Children</th>
                            :
                            null
                          }

                          {
                            this.state.companyprofile.showuniversity?
                            <th>University</th>
                            :
                            null
                          }

                       <th>Journal number</th>

                        </tr>
                      </thead>
                      <tbody>
                      {this.props.sheetitems.map(
                        (item)=>(
                          <tr>
                          <td>{item.time_stamp}</td>
                          <td>{item.description}</td>
                          <td>{item.subaccount}</td>

                          <td>{<CurrencyFormat value={item.debit} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.credit} displayType={'text'} thousandSeparator={true}/>}</td>
                         
                          <td>{<CurrencyFormat value={item.balance} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{item.sharesnumber_balance} </td>
                          <td>{<CurrencyFormat value={item.sharesamount_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.loan_balance} displayType={'text'} thousandSeparator={true}/>}</td>                         

                          <td>{<CurrencyFormat value={item.savingsloan_security_balance} displayType={'text'} thousandSeparator={true}/>}</td>                         
                          <td>{<CurrencyFormat value={item.shares_security_balance} displayType={'text'} thousandSeparator={true}/>}</td>                         

                          {
                            this.state.companyprofile.showwelfare?
                            <td>{item.welfare_balance}</td>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showemergency?
                            <td>{item.emergency_balance}</td>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showdream?
                            <td>{item.dream_balance}</td>
                            :
                            null
                          }

                          {
                            this.state.companyprofile.showlegacy?
                            <td>{item.legacy_balance}</td>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showchildren?
                            <td>{item.children_balance}</td>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showuniversity?
                            <td>{item.university_balance}</td>
                            :
                            null
                          }

                        <td>{item.journal_no} </td>

                          </tr>
                        ))}

<tr>
                          <td><h3>Savings: <CurrencyFormat  value={this.props.selectedmemberobject.UserBalance} displayType={'text'} thousandSeparator={true} /></h3> </td>
                          <td> <h3>Shares amount  : <CurrencyFormat  value={this.props.selectedmemberobject.sharesamount_balance} displayType={'text'} thousandSeparator={true} /></h3></td>
                          <td><h3>Shares no : {this.props.selectedmemberobject.sharesnumber_balance}</h3></td>

                          <td><h3>Loan : <CurrencyFormat  value={this.props.selectedmemberobject.loan_balance} displayType={'text'} thousandSeparator={true} /></h3></td>

                          <td><h3>Reserve (Savings loan security) : <CurrencyFormat  value={this.props.selectedmemberobject.savingsloan_security_balance} displayType={'text'} thousandSeparator={true} /></h3></td>
                          <td><h3>Shares loan security : <CurrencyFormat  value={this.props.selectedmemberobject.shares_security_balance} displayType={'text'} thousandSeparator={true} /></h3></td>

                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>

                        </tr>

                    </tbody>
                </reactstrp.Table>




                <br></br>
                <h3>Generated by: {CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)}</h3>
                <h3>Powered by: www.pitech.co.ug</h3>   
        </div>
      );
    }
  }


class FinancialStatementReport extends React.Component {

  state = {
    members:[],    
    sheetitems:[],
    dateone:'',
    datetwo:'',
    selectedmemberobject:{},
    selectedmemberid:'',
    totaldebit:0,
    totalcredit:0,
    datarequested:false,
    companyprofile:{},
    userprofile:{},

    deletelist:[],

    editmodalvisiblae:false,
    selectedtranobj:{},
    credit:0,
    debit:0,
    balance:0,
    transdesc:'',

    sharesamount_balance:0,
    sharesnumber_balance:0,
    loan_balance:0,
    savingsloan_security_balance:0,
    shares_security_balance:0,

   };

   componentDidMount(){
      if(localStorage.getItem("sacco")){
        sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
        bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    
    }else{
        sacco= ''
        bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
        token= localStorage.getItem("token")
    }else{
        token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

    axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          members:res.data
        })
      })

      axios.get(`${serverconfig.backendserverurl}/api/accounts/${bizuserid}`)
      .then(res => {  
          this.setState({
            userprofile: res.data
          })
      })

  }
 
   handledatechange= (date, dateString) =>{
     this.setState({ dateone: dateString[0]});
     this.setState({ datetwo: dateString[1]});
   } 

   handlememberChange= (e) =>{
    this.setState({ selectedmemberid: e});

    axios.get(`${serverconfig.backendserverurl}/api/members/${e}`)
    .then(res => {  
        this.setState({selectedmemberobject: res.data})
    })

   } 

 
   //search sales method
   searchSales=()=>{

    let form_data = new FormData();
    form_data.append('dateone', this.state.dateone);
    form_data.append('datetwo', this.state.datetwo);
    form_data.append('memberid', this.state.selectedmemberid);
    form_data.append('sacco', sacco);
    
    console.log(this.state.entrant)
      
    if(this.state.selectedmemberid===''||this.state.dateone===''||this.state.datetwo===''){
      alert("Please select entrant or dates missing")
    }else{
        this.setState({datarequested:true})

        //Now submit sale data to database
        axios.post(serverconfig.backendserverurl+'/customqueries/getmemberfinancialstatement', form_data,{
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
        .then(res =>{
          this.setState({datarequested:false})

            this.setState({sheetitems:JSON.parse(res.data.report)})
            this.setState({totaldebit:res.data.totalDebit})
            this.setState({totalcredit:res.data.totalCredit})
        } 
        )
        .catch(error => console.log(error))   
    }

    }

   ////////////////////////////////////
  // USER SEARCH SELECT METHODS
  onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }
 

   ////////////////////////////////////////////
   // RENDERING METHOD HERE
   render() {
       
       if(this.state.datarequested===true){
        return(
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
          <Spin indicator={antIcon} />
          </div>
        )
      }else{

        return(
          <div>
            <div style={{display: "none"}}>
               &nbsp;&nbsp;
              <ReportToPrint
              totaldebit={this.state.totaldebit} 
              totalcredit={this.state.totalcredit} 
              dateone={this.state.dateone}
              datetwo={this.state.datetwo}
              sheetitems={this.state.sheetitems}
              selectedmemberobject={this.state.selectedmemberobject}
              ref={el => (this.componentRef = el)} /> 
            </div>
  
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
              <Panel header="MEMBER FINANCIAL STATEMENT REPORT" key="1">
              <Form  layout="inline" >
 
              <FormItem label="Members">
                     <Select 
                     placeholder="Account"
                     style={{ width: 300 }} 
                     value={this.state.selectedmemberid} 
                     onChange={this.handlememberChange}
                      
                     showSearch
                     optionFilterProp="children"
                     onFocus={this.onFocus}
                     onBlur={this.onBlur}
                     onSearch={this.onSearch}                     
                      >
                       {this.state.members.map(
                         (member)=>(
                           <Option value={member.id}>{member.AccountNumber} | {member.FullName}</Option>
                         ))}
                     </Select>
                 </FormItem>
 
 
                  <FormItem label="Date Range">
                      <RangePicker defaultValue={moment()} onChange={this.handledatechange} format={dateFormat} />
                  </FormItem>
  
                  <FormItem>
                  <Button onClick={this.searchSales} type="primary" htmlType="button">Search</Button>
                  </FormItem> 
  
             </Form>
  
             <br></br>
             <div style={{overflowX : 'auto'}}>
             <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Desc</th>
                          <th>Sub account</th>

                          <th>Debit</th>
                          <th>Credit</th>
                          
                          <th>Savings balance</th>
                          <th>Shares #</th>
                          <th>Shares Amount</th>
                          
                          <th>Loan balance</th>

                          <th>Savings loan security</th>
                          <th>Shares loan security</th>


                          {
                            this.state.companyprofile.showwelfare?
                            <th>Welfare</th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showemergency?
                            <th>Emergency</th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showdream?
                            <th>Dream</th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showlegacy?
                            <th>Legacy</th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showchildren?
                            <th>Children</th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showuniversity?
                            <th>University</th>
                            :
                            null
                          }

                       <th>Journal number</th>

                       {
                            this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true?
                            <th>Edit</th>
                            :
                            null
                          }



                          {
                            this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true?
                            <th>Delete</th>
                            :
                            null
                          }

                          {
                            this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true?
                            <th>Select</th>
                            :
                            null
                          }

                        </tr>
                      </thead>
                      <tbody>
                      {this.state.sheetitems.map(
                        (item)=>(
                          <tr>
                          <td>{item.time_stamp}</td>
                          <td>{item.description}</td>
                          <td>{item.subaccount}</td>

                          <td>{<CurrencyFormat value={item.debit} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.credit} displayType={'text'} thousandSeparator={true}/>}</td>
                         
                          <td>{<CurrencyFormat value={item.balance} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{item.sharesnumber_balance} </td>
                          <td>{<CurrencyFormat value={item.sharesamount_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.loan_balance} displayType={'text'} thousandSeparator={true}/>}</td>                         

                          <td>{<CurrencyFormat value={item.savingsloan_security_balance} displayType={'text'} thousandSeparator={true}/>}</td>                         
                          <td>{<CurrencyFormat value={item.shares_security_balance} displayType={'text'} thousandSeparator={true}/>}</td>                         


                          {
                            this.state.companyprofile.showwelfare?
                            <td>{item.welfare_balance}</td>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showemergency?
                            <td>{item.emergency_balance}</td>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showdream?
                            <td>{item.dream_balance}</td>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showlegacy?
                            <td>{item.legacy_balance}</td>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showchildren?
                            <td>{item.children_balance}</td>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showuniversity?
                            <td>{item.university_balance}</td>
                            :
                            null
                          }

                        <td>{item.journal_no} </td>


                        {
                          this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true?
                          <th>
                          <Popover content={<p>Click here to edit transaction</p>} title="Activate">
                          <EditOutlined style={{color:'green'}} onClick={()=>{
                            //set selected object here
                            axios.get(serverconfig.backendserverurl+`/api/transactions/${item.id}`)
                            .then(res => {
                                this.setState({selectedtranobj:res.data})
                                this.setState({transdesc:res.data.transaction_details})

                            })

                            this.setState({editmodalvisiblae:true})
                            this.setState({credit:item.credit})
                            this.setState({debit:item.debit})
                            this.setState({balance:item.balance})

                            this.setState({sharesamount_balance:item.sharesamount_balance})
                            this.setState({sharesnumber_balance:item.sharesnumber_balance})
                            this.setState({loan_balance:item.loan_balance})
                            this.setState({savingsloan_security_balance:item.savingsloan_security_balance})
                            this.setState({shares_security_balance:item.shares_security_balance})



                          }}/>
                          </Popover>

                          </th>
                          :
                          null
                        }


                        {
                          this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true?
                          <th>
                            <Popover content={<p>Click here to delete</p>} title="Delete">
                            <Popconfirm title="Sure to delete?" onConfirm={() => {
                              axios.delete(`${serverconfig.backendserverurl}/api/transactions/${item.id}`).then(res =>{
                                window.location.reload(false)
                                message.info("successfully deleted") 
                              }
                              );
                            }}>
                              <DeleteOutlined style={{color:'red'}}/>
                            </Popconfirm>
                            </Popover>

                          </th>
                          :
                          null
                        }

                          
                          {
                            this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true?
                            <th>
                              
                            <PlusOutlined  style={{color:this.state.deletelist.indexOf(item.id)> -1?'red':'green'}}  onClick={()=>{

                              if(this.state.deletelist.indexOf(item.id)> -1){
                                console.log("trans removed")
                                const newList = this.state.deletelist.filter((trans) => trans!== item.id);
                                this.setState({deletelist:newList});
                              }else{

                                this.setState({
                                  deletelist: [...this.state.deletelist, item.id]
                                  });

                                  console.log("trans added")

                              }
                                
                            }}/>

                            </th>
                            :
                            null
                          }



                          </tr>
                        ))}

                        <tr>
                          <td><h3>Savings: <CurrencyFormat  value={this.state.selectedmemberobject.UserBalance} displayType={'text'} thousandSeparator={true} /></h3> </td>
                          <td> <h3>Shares amount  : <CurrencyFormat  value={this.state.selectedmemberobject.sharesamount_balance} displayType={'text'} thousandSeparator={true} /></h3></td>
                          <td><h3>Shares no : {this.state.selectedmemberobject.sharesnumber_balance}</h3></td>

                          <td><h3>Loan : <CurrencyFormat  value={this.state.selectedmemberobject.loan_balance} displayType={'text'} thousandSeparator={true} /></h3></td>
                          <td><h3>Reserve (Savings loan security) : <CurrencyFormat  value={this.state.selectedmemberobject.savingsloan_security_balance} displayType={'text'} thousandSeparator={true} /></h3></td>
                          <td><h3>Shares loan security : <CurrencyFormat  value={this.state.selectedmemberobject.shares_security_balance} displayType={'text'} thousandSeparator={true} /></h3></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>

                          {
                            this.state.companyprofile.showuniversity?
                            <td></td>
                            :
                            null
                          }

                          {
                            this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true?
                            <td></td>
                            :
                            null
                          }

                          {
                            this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true?
                            <td></td>
                            :
                            null
                          }

                        </tr>

                    </tbody>
                </reactstrp.Table>  
              <br></br>


              </div>
              <br></br>

              <div style={{display:'flex',justifyContent:'flex-end',alignSelf:'flex-end',alignItems:'flex-end'}}>
               
               {
                  this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true?

               <Button  type="primary" htmlType='button'
                 onClick={()=>{
                   this.setState({datarequested:true})

                   let form_data = new FormData();
                   form_data.append('deletemode','transactions');
                   form_data.append('deletelist',JSON.stringify(this.state.deletelist) );

                   axios.post(serverconfig.backendserverurl+'/customqueries/massdeleting', form_data, {
                     headers: {
                       'content-type': 'multipart/form-data'
                     }
                     })
                   .then(res => {
                     //get new data back
                     this.setState({datarequested:false})
                     this.setState({deletelist:[]})
                     window.location.reload(false)

                   })
                   .catch(error => console.log(error))

                 }}
                 
                 >Batch Delete</Button>
                 :
                 null
                 }


                 &nbsp;
                 &nbsp;
                 &nbsp;
                 &nbsp;
                 &nbsp;

              
               <ReactToPrint
                 trigger={() => 
                 <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
                 Print
                 </Button>
                 }
                 content={() => this.componentRef}
                 />
                 &nbsp;
                 &nbsp;
                 &nbsp;
                 &nbsp;
                 &nbsp;

               <Button type='primary'
                icon={<MailFilled/>}
                
                onClick={
                 ()=>{
                   let form_data = new FormData();
                   form_data.append('dateone', this.state.dateone);
                   form_data.append('datetwo', this.state.datetwo);
                   form_data.append('memberid', this.state.selectedmemberid);
                   form_data.append('sacco', sacco);
                   
                   console.log(this.state.entrant)
                     
                   if(this.state.selectedmemberid===''||this.state.dateone===''||this.state.datetwo===''){
                     alert("Please select entrant or dates missing")
                   }else{
                       this.setState({datarequested:true})
               
                       //Now submit sale data to database
                       axios.post(serverconfig.backendserverurl+'/customqueries/emailmemberfinancialstatement', form_data,{
                       headers: {
                         'content-type': 'multipart/form-data'
                       }
                       })
                       .then(res =>{
                         this.setState({datarequested:false})
                         message.info(res.data.message)
                       } 
                       )
                       .catch(error => console.log(error))   
                   }
                 }
                }
                
                >
                 Send via eMail
               </Button>

             </div>

              </Panel>
              </Collapse>

              <Modal              
              visible={this.state.editmodalvisiblae}
              title={"Transaction Edit Form"}
              onCancel={()=>{this.setState({editmodalvisiblae:false})}}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({editmodalvisiblae:false})}}>
                  Cancel
                </Button>
                ]}
                >

              <FormItem 
                    label="Debit"
                    >
              <Input name="Debit"  placeholder="debit." value={this.state.debit} onChange={(val)=>{this.setState({debit:val.target.value})}} />                          
              </FormItem>

              <FormItem 
                    label="Credit"
                    >
              <Input name="Credit"  placeholder="credit." value={this.state.credit} onChange={(val)=>{this.setState({credit:val.target.value})}} />                          
              </FormItem>

              <FormItem 
                    label="Balance"
                    >
              <Input name="Balance"  placeholder="balance." value={this.state.balance} onChange={(val)=>{this.setState({balance:val.target.value})}} />                          
              </FormItem>

              <FormItem 
                    label="sharesamount_balance"
                    >
              <Input name="sharesamount_balance"  placeholder="sharesamount_balance." value={this.state.sharesamount_balance} onChange={(val)=>{this.setState({sharesamount_balance:val.target.value})}} />                          
              </FormItem>

              <FormItem 
                    label="sharesnumber_balance"
                    >
              <Input name="sharesnumber_balance"  placeholder="sharesnumber_balance." value={this.state.sharesnumber_balance} onChange={(val)=>{this.setState({sharesnumber_balance:val.target.value})}} />                          
              </FormItem>

              <FormItem 
                    label="loan_balance"
                    >
              <Input name="loan_balance"  placeholder="loan_balance." value={this.state.loan_balance} onChange={(val)=>{this.setState({loan_balance:val.target.value})}} />                          
              </FormItem>

              <FormItem 
                    label="savingsloan_security_balance"
                    >
              <Input name="savingsloan_security_balance"  placeholder="savingsloan_security_balance." value={this.state.savingsloan_security_balance} onChange={(val)=>{this.setState({savingsloan_security_balance:val.target.value})}} />                          
              </FormItem>

              <FormItem 
                    label="shares_security_balance"
                    >
              <Input name="shares_security_balance"  placeholder="shares_security_balance." value={this.state.shares_security_balance} onChange={(val)=>{this.setState({shares_security_balance:val.target.value})}} />                          
              </FormItem>

              <FormItem 
                    label="Trans Desc"
                    >
              <Input name="desc"  placeholder="desc." value={this.state.transdesc} onChange={(val)=>{this.setState({transdesc:val.target.value})}} />                          
              </FormItem>

           <Button  type="primary" htmlType='button'
            onClick={()=>{
              let form_data = new FormData();
              form_data.append('credit',this.state.credit);
              form_data.append('debit', this.state.debit);
              form_data.append('balance', this.state.balance);
              form_data.append('transid', this.state.selectedtranobj.id);
              form_data.append('transaction_details', this.state.transdesc);


              form_data.append('sharesamount_balance', this.state.sharesamount_balance);
              form_data.append('sharesnumber_balance', this.state.sharesnumber_balance);
              form_data.append('loan_balance', this.state.loan_balance);
              form_data.append('savingsloan_security_balance', this.state.savingsloan_security_balance);
              form_data.append('shares_security_balance', this.state.shares_security_balance);

              axios.post(serverconfig.backendserverurl+`/customqueries/edittransaction`, form_data, {
                headers: {
                  'content-type': 'multipart/form-data'
                }
                })
              .then(res => {
                console.log(res.data)
                this.setState({editmodalvisiblae:false})  
                window.location.reload(false)

              })
              .catch(error => console.log(error))
            }}
            
            >Update</Button>

            </Modal> 
  
          </div>
      )        

      }
   
   }
}

export default FinancialStatementReport; 
