export const backendserverurl='https://9-be-n3xt-n4bu.pitech.co.ug'

//https://1-be-pifs01-mta-app1.pitech.co.ug
//127.0.0.1:8000
//138.197.8.179
//http://138.197.8.179:10226
//https://2-be-pifs02-mta-app2.pitech.co.ug


//https://7-be-n3xt-d0va.pitech.co.ug
//https://8-be-n3xt-m4sa.pitech.co.ug

//https://9-be-n3xt-n4bu.pitech.co.ug
//https://10-be-n3xt-3lys.pitech.co.ug
//https://11-be-n3xt-kc4s.pitech.co.ug

