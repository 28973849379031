import React from 'react';
import { Form, Input,message,Button,Select,DatePicker,InputNumber,Modal,Spin,Result } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import moment from 'moment';
import {
  Card,
  Col,
  Row,
} from 'reactstrap';
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");


const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});



var token= ''
var sacco= ''
var username=''


class IncomeForm extends React.Component {

  state = {
    staffs:[],
    incomecategories:[],
    date:moment().format(dateFormat).toString(),  
    quantity:0,
    rate:0,
    amounttobepaid:0,
    balance:0,
    description:'',
    expensecategory:'',
    expenseto:'',
    expensetocontact:'',
    debitorphonetwo:'',
    lastincomeno:'',
    incomeno:'',
    expensetotype:'',
    members:[],
    selectedaccount:'',
    datasubmittedsuccessfully:false,


    ////////////////////////////////
    //payments form variables
    amountpaid:0,
    paymentmode:'',
    amount:'',
    comments:'',
    phonenumberfrom:'',
    phonenumberto:'',
    accountto:'',
    clientbank:'',
    transreference:'',
    chequeno:'',
    datarequested:true,

    companyaccounts: [],
    debitaccount:''
  }
  

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          members:res.data
        })

    })


    axios.get(serverconfig.backendserverurl+`/api/staffs/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          staffs:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/incomecategories/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          incomecategories:res.data
        })

    })


    //get last slip number
    let form_data = new FormData();
    form_data.append('sacco', sacco);
    axios.post(serverconfig.backendserverurl+'/customqueries/getsaccolastincomeno', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({lastincomeno:res.data.lastnumber})
        this.setState({incomeno:Number(res.data.lastnumber)+1})

        //this.setState({datarequested:false})
    })
    .catch(error => console.log(error))

    axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
            companyaccounts:res.data
        })
        this.setState({datarequested:false})
    })

}

showPaymentModal=()=>{
  this.setState({ viewpaymentmodal: true });
  
}

closepaymentmodal = () => {
  this.setState({ viewpaymentmodal: false });
 };

  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('date', this.state.date);
    form_data.append('income_category', this.state.expensecategory);
    form_data.append('incomefrom_name', this.state.expenseto);
    form_data.append('incomefrom_type', this.state.expensetotype);
    form_data.append('incomefrom_contact', this.state.expensetocontact);
    form_data.append('rate', this.state.rate);
    form_data.append('quantity', this.state.quantity);
    form_data.append('amount_paid', this.state.amountpaid);
    form_data.append('total_to_be_paid', this.getTotalPayment());
    form_data.append('details', this.state.description);
    form_data.append('user', username);
    form_data.append('incomeno', this.state.incomeno);
    form_data.append('selectedaccount', this.state.selectedaccount);

    //payment variables here
    form_data.append('comments', this.state.comments);
    form_data.append('phonenumberfrom', this.state.phonenumberfrom);
    form_data.append('phonenumberto', this.state.phonenumberto);
    form_data.append('accountto',this.state.accountto);
    form_data.append('clientbank',this.state.clientbank);
    form_data.append('transreference', this.state.transreference);
    form_data.append('chequeno',this.state.chequeno);
    form_data.append('sacco', sacco);
    form_data.append('debitaccount', this.state.debitaccount);
    form_data.append('paymentmode', this.state.paymentmode);
    
    axios.post(serverconfig.backendserverurl+'/customqueries/createincome', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res => {
    this.setState({datarequested:false})
    message.info(res.data.message)
    if (res.data.message==="success"){
      this.setState({datasubmittedsuccessfully:true})
    }
  }        

  )
  .catch(error => console.log(error))

  }

  handledatechange= (date, dateString) => this.setState({ date: dateString});
  handlequantityChange= (e) => this.setState({ quantity: e.target.value});
  handlerateChange= (e) => this.setState({ rate: e.target.value});

  handleamountpaidChange= (e) => this.setState({ amountpaid: e.target.value});
  handlebalanceChange= (e) => this.setState({ balance: e.target.value});

  handledescriptionChange= (e) => this.setState({ description: e.target.value});

  getTotalPayment=()=>{
    var total=0;
    total=Number(this.state.rate)*Number(this.state.quantity)
    return total;
  }


  handleexpensecategoryChange= (e) => this.setState({ expensecategory: e});
  handleexpensetoChange= (e) => this.setState({ expenseto: e.target.value});

  handleexpensetocontactChange= (e) => this.setState({ expensetocontact: e});
  handledebitorphonetwoChange= (e) => this.setState({ debitorphonetwo: e});

  handleincomenoChange= (e) => this.setState({ incomeno: e.target.value});

  handlepaymentmodeChange= (e) => {
      this.setState({paymentmode:e})
  }

  handleexpensetotypeChange= (e) => {
    this.setState({expensetotype:e})
  }

  handleselectedaccountChange= (e) =>{
    this.setState({ selectedaccount: e});
  }

  ////////////////////////////////////
  // PRODUCT SEARCH SELECT METHODS
  onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }


  onPaymentFinish=(event) =>{
    var paymentmode=event.paymentmode;
    var amount=event.amount;
    var comments=event.comments;
    var phonenumberfrom=event.phonenumberfrom;
    var phonenumberto=event.phonenumberto;
    var accountto=event.accountto;
    var clientbank=event.clientbank;
    var transreference=event.transreference;
    var chequeno=event.chequeno;
  
    this.setState({paymentmode: paymentmode});
    this.setState({ amountpaid: amount});
    this.setState({comments:comments});
    this.setState({phonenumberfrom:phonenumberfrom});
    this.setState({phonenumberto: phonenumberto});
    this.setState({accountto:accountto});
    this.setState({clientbank:clientbank});
    this.setState({transreference: transreference});
    this.setState({chequeno:chequeno});
    this.setState({ viewpaymentmodal: false });
  
    }

  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
            <Card>
            <Result
            status="success"
            title="Successfully Created Income "
            subTitle="Incomes are reported in the Income Statement"
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
            </Card>
        );

      }else{


        return (
          <div>
            <Form 
            name="expense Form"
            onFinish={(event) => this.handleFormSubmit(event)}
            >
    
          <FormItem label="Date"
                      name='date'
                      rules={[
                        {
                          required: true,
                          message: 'Please Select date',
                        },
                      ]}
                    >
                        <DatePicker value={moment()} onChange={this.handledatechange} format={dateFormat} />
                    </FormItem>
          
    
          
          <FormItem label="Income Category"
              name="expensecategory"
              rules={[
                {
                  required: true,
                  message: 'Please Select Income category',
                },
              ]}
            >
                <Select placeholder="Income Category" style={{ width: 120 }} value={this.state.expensecategory} onChange={this.handleexpensecategoryChange} >
                  {this.state.incomecategories.map(
                    (ctgry)=>(
                      <Option value={ctgry.id}>{ctgry.category_name}</Option>
                    ))}
                </Select>
            </FormItem>
            
            
            <FormItem 
              label="Income From Type"
              name="expensetotype"
              rules={[
                {
                  required: true,
                  message: 'Please select payment mode',
                },
              ]}
              >
                  <Select 
                  value={this.state.expensetotype} 
                  onChange={this.handleexpensetotypeChange}
                  placeholder="Account Holder Type" >
                        <Option value='accountholder'>Account Holder</Option>
                        <Option value='nonaccountholder'>Non Account Holder</Option>
                  </Select>
              </FormItem>
  
              {
                this.state.expensetotype==="nonaccountholder"?
                <div>
                  <FormItem label="Income from"
                  name="expenseto"
                  rules={[
                    {
                      required: false,
                      message: 'Please enter name',
                    },
                  ]}          
                
                
                >
                  <Input  placeholder="expenseto." value={this.state.expenseto} onChange={this.handleexpensetoChange} />
                </FormItem>
        
              
              <FormItem label="Income From contact" >
                <PhoneInput
                      countrySelectProps={{ unicodeFlags: true }}
                      defaultCountry="UG"
                      placeholder="Enter phone"
                      value={this.state.expensetocontact} onChange={this.handleexpensetocontactChange}/>
                </FormItem>
                </div>
                :
                <FormItem label="Select Account">
                    <Select 
                    placeholder="Account No | Name" 
                    style={{  }} 
                    value={this.state.selectedaccount}
                    onChange={this.handleselectedaccountChange} 
                    showSearch
                    optionFilterProp="children"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}
                                        
                    >
                      {this.state.members.map(
                        (accnt)=>(
                          <Option value={accnt.id}>{accnt.AccountNumber} | {accnt.FullName}</Option>
                        ))}
                    </Select>
                </FormItem>
  
              }
  
  
            
          <FormItem label="Quantity"
                      name="quantity"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Qunatity',
                        },
                      ]}             
                    
                    >
                      <Input name="quantity" type="number"  placeholder="Quantity" value={this.state.quantity} onChange={this.handlequantityChange}/>
                    </FormItem>
            
          
          <FormItem label="Rate"
                      name="rate"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Rate',
                        },
                      ]}             
                    
                    >
                      <Input name="rate" type="number"  placeholder="Rate" value={this.state.rate} onChange={this.handlerateChange}/>
                    </FormItem>
            
            
            <FormItem label="Amount to be paid">
              <Input  type="number"  placeholder="Amount to be paid" value={this.getTotalPayment()}  readOnly/>
            </FormItem>
            
    
          <FormItem label="Amount Paid">
              <Input name="amountpaid" type="number" placeholder="Amount paid" value={this.state.amountpaid} onChange={this.handleamountpaidChange} onClick={this.showPaymentModal}/>
            </FormItem>
            
    
            <FormItem label="Income No">
            <Input name="incomeno"   placeholder="Income No" value={this.state.incomeno} onChange={this.handleincomenoChange}/>
          </FormItem>
  
  
          <FormItem label="Description">
                <TextArea
                      placeholder="Details."
                      autoSize={{ minRows: 2, maxRows: 6 }}
                      value={this.state.description}
                      onChange={this.handledescriptionChange}
                    />
          </FormItem>
    
          {
            this.state.paymentmode==="accountdebit"?
            null
            :
            <FormItem label={<h4 style={{fontWeight:'bold'}}>Receiving AC (Credit A/C).</h4>}
            name="debitaccount"
            rules={[
              {
                required: true,
                message: 'Please select account',
              },
            ]}
          >
            <Select 
            placeholder="Account" 
            style={{  }} 
            value={this.state.debitaccount}
            onChange={(val)=>{this.setState({debitaccount:val})}} 
            showSearch
            optionFilterProp="children"
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onSearch={this.onSearch}                    
            >
              {this.state.companyaccounts.map(
                (accnt)=>(
                  <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                ))}
            </Select>
          </FormItem>
          }
          


          <FormItem>
            <Button type="primary" htmlType="submit" >Create</Button>
          </FormItem>
       </Form>
    
    
    
       {/* expense payment modal */}
       <Modal
              visible={this.state.viewpaymentmodal}
              title="Income Payment Form"
              width={600}
              onCancel={this.closepaymentmodal}
              footer={[
                <Button key="back" onClick={this.closepaymentmodal}>
                  Cancel
                </Button>,
                ]}
                >
              <Form
                  name="paymentform"
                  onFinish={(event) => this.onPaymentFinish(event)}
              >
    
               <FormItem 
                      label="Payment Mode"
                      name="paymentmode"
                      rules={[
                        {
                          required: true,
                          message: 'Please select payment mode',
                        },
                      ]}
                      >
                          <Select 
                          value={this.state.paymentmode} 
                          onChange={this.handlepaymentmodeChange}
                          placeholder="Payment Mode" >
                                <Option value='cash'>Cash</Option>
                                {
                                  this.state.expensetotype==="accountholder"?
                                  <Option value='accountdebit'>Account Debit</Option>
                                  :
                                  null
                                }
                                <Option value='mobilemoney'>Mobile Money</Option>
                                <Option value='cheque'>Cheque</Option>
                                <Option value='electronicfundtransfer'>Electronic Fund Transfer</Option>                         
  
                          </Select>
                </FormItem>
    
                <FormItem label="Amount"
                  name="amount"
                  rules={[
                    {
                      required: true,
                      message: 'Please input amount',
                    },
                  ]}
                  
                  >
                    <InputNumber
                      style={{ width: 150 }} 
                      defaultValue={0.0}
                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={value => value.replace(/\x\s?|(,*)/g, '')}
                    />    
                                
                  </FormItem>
                  
                  {
                    this.state.paymentmode=="mobilemoney"?
    
                    <div>
                    <FormItem 
                    label="Phone From"
                    name="phonenumberfrom"
                    rules={[
                      {
                        required: false,
                        message: 'Please input Phone',
                      },
                    ]}
                    
                    >
                          <PhoneInput
                                countrySelectProps={{ unicodeFlags: true }}
                                defaultCountry="UG"
                                placeholder="Enter phone"
                                />
                    </FormItem> 
    
                    <FormItem 
                    label="Phone To"
                    name="phonenumberto"
                    rules={[
                      {
                        required: false,
                        message: 'Please input Phone',
                      },
                    ]}
                    
                    >
                          <PhoneInput
                                countrySelectProps={{ unicodeFlags: true }}
                                defaultCountry="UG"
                                placeholder="Enter phone"
                                />
                    </FormItem> 
                    </div>
                    :
                    <br></br>
                  }
    
                    {
                    this.state.paymentmode=="electronicfundtransfer"               
                    ?
                    <div>
    
                  <FormItem label="Account To"
                    name="accountto"
                    rules={[
                      {
                        required: false,
                        message: 'Please input account',
                      },
                    ]}
                  
                  >
                    <Input placeholder="Account To"  />
                  </FormItem>
              
                  <FormItem label="Client Bank"
                    name="clientbank"
                    rules={[
                      {
                        required: false,
                        message: 'Please input bank',
                      },
                    ]}
                  
                  >
                    <Input placeholder="Client Bank"  />
                  </FormItem>
              
              
                  <FormItem label="Transaction Reference"
                    name="transreference"
                    rules={[
                      {
                        required: false,
                        message: 'Please input reference',
                      },
                    ]}
                  
                  >
                    <Input placeholder="Reference"  />
                  </FormItem>
    
                    </div>
    
                    :
                    <br></br>
    
                  }
    
    
                  {
                    this.state.paymentmode=="cheque"                
                    ?
                    <div>
    
                  <FormItem label="Cheque Number"
                    name="chequeno"
                    rules={[
                      {
                        required: false,
                        message: 'Please input cheque number',
                      },
                    ]}
                  
                  >
                    <Input placeholder="Cheque Number"  />
                  </FormItem>
            
                    </div>
    
                    :
                    <br></br>
    
                  }
    
    
                <FormItem label="Comments"
                name="comments"
                rules={[
                  {
                    required: false,
                    message: 'Please input comments',
                  },
                ]}
                
                >
                    <TextArea
                      placeholder="Comment here"
                      autoSize={{ minRows: 2, maxRows: 6 }}
                    />
                </FormItem>
    
                <FormItem>
                  <Button  type="primary" htmlType="submit">Add Payment</Button>
                </FormItem>
                </Form>
    
          </Modal>
    
          </div>
        );

      }

    }

  }

}


export default IncomeForm;



